import { PropsWithChildren } from 'react';

type StageLabel = PropsWithChildren;

/**
 * Renders the label for a stage slider item component.
 */
export default function StageSliderItemLabel({ children }: StageLabel) {
  return <div className="dg-text-regular-3 text-brandYellowC1">{children}</div>;
}
