import { PropsWithChildren } from 'react';
import ContainerGrid from 'src/components/container-grid';

type StageContentProps = PropsWithChildren;

/**
 * Container for the content of a stage slider item. Typically contains the label, title and optional extra content.
 *
 * @example
 * ```tsx
 * <StageSliderItemContent>
 *   <StageSliderItemLabel>{label}</StageSliderItemLabel>
 *   <StageSliderItemTitle primary={title} secondary={subtitle} center />
 * </StageSliderItemContent>
 * ```
 */
export default function StageContent({ children }: StageContentProps) {
  return (
    <div className="lg:absolute lg:inset-0">
      <ContainerGrid fullHeight>
        <div className="relative col-span-12 flex flex-col items-center justify-center gap-y-6 lg:col-span-5 lg:col-start-8 xl:col-span-4 xl:col-start-8">
          {children}
        </div>
      </ContainerGrid>
    </div>
  );
}
