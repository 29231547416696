import Image, { ImageProps } from 'next/image';
import { Picture, PictureType } from 'generated/graphql';
import ImageSquare from 'src/components/common/image-square';
import { ImageWide } from 'src/components/common/image-wide';
import { getPictureByType } from 'src/utilities/image-helpers';
import { cloudinaryLoaderSquare } from 'src/utilities/image-sdk';

type StageImage = Omit<ImageProps, 'src'> & {
  pictures: Picture[];
  pictureType: typeof PictureType.Cover | typeof PictureType.Teaser;
};

// The maximum dimensions that the album cover can be inside the stage slider
const maxAlbumCoverSize = 320;

/**
 * Renders a responsive image for a stage slider item. The image is either a cover image or a teaser image.
 */
export default function StageSliderItemImage({ pictures, pictureType, alt, ...imageProps }: StageImage) {
  const picture = getPictureByType(pictures, pictureType);

  // If the picture type is a cover image, we render a square cover image with a blurred background of the same image
  if (pictureType === PictureType.Cover) {
    return (
      <div className="relative aspect-video w-full md:h-full lg:w-3/5">
        {picture && (
          <div className="absolute inset-0">
            {/* Blurred background version of album cover that re-uses the same image src as the main cover image */}
            <Image
              className="object-cover blur-xl fade-to-b lg:fade-to-r"
              src={cloudinaryLoaderSquare({ src: picture.url, width: maxAlbumCoverSize })}
              fill
              unoptimized // does not set the `srcSet` attribute because we don’t need larger resolutions here
              alt=""
            />
          </div>
        )}
        <div className="inset-0 my-4 flex justify-center md:my-0 md:items-center lg:absolute">
          <div className="size-64 overflow-hidden rounded lg:size-80">
            <ImageSquare
              {...imageProps}
              alt={alt}
              src={picture?.url}
              width={maxAlbumCoverSize}
              height={maxAlbumCoverSize}
              placeholder="default"
            />
          </div>
        </div>
      </div>
    );
  }

  // Otherwise, we render a wide teaser image
  return (
    <div className="relative w-full fade-to-b md:h-full lg:w-3/5 lg:fade-to-r mouse:transition-transform mouse:group-hover:scale-105">
      <ImageWide
        {...imageProps}
        alt={alt}
        src={picture?.url}
        // Prefer image size of 3/5 (60%) of viewport width on larger breakpoints and 100vw on mobile
        sizes="(min-width: 768px) 60vw, 100vw"
        fill
      />
    </div>
  );
}
