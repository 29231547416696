import type { SVGProps } from 'react';
const SvgReLive = (props: SVGProps<SVGSVGElement>) => (
  <svg width={24} height={24} fill="none" viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M5.78 17.619C7.298 19.143 9.289 20 11.469 20h.285c2.275 0 4.36-.857 5.877-2.381S20 14.095 20 12s-.948-4.095-2.465-5.619C15.924 4.857 13.743 4 11.658 4s-4.076.762-5.688 2.095l-.568-.571c-.095-.19-.19-.19-.38-.19s-.474.095-.474.476l-.284 2.476c0 .19.095.38.19.476.094.19.284.19.568.19l2.56-.19c.284-.095.474-.381.474-.381.095-.19 0-.381-.19-.571l-.474-.477C8.53 6.381 9.857 5.81 11.563 5.81a6.37 6.37 0 0 1 4.55 1.904c1.138 1.238 1.802 2.667 1.802 4.286 0 3.333-2.844 6.095-6.352 6.095a6.35 6.35 0 0 1-5.782-3.714c-.095-.381-.474-.572-.853-.572a.7.7 0 0 0-.237.048.7.7 0 0 1-.237.048c-.38.286-.57.762-.38 1.238.38.952.948 1.714 1.707 2.476Z"
    />
    <path fill="currentColor" d="M11.6 13.8a2 2 0 1 0 0-4 2 2 0 0 0 0 4" />
  </svg>
);
export default SvgReLive;
