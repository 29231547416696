import ReLiveIcon from '@stageplus/icons/react/re-live';
import { LiveConcert } from 'generated/graphql';
import RenderClientOnly from 'src/components/render-client-only';
import Time from 'src/components/time';
import useLiveConcertState from 'src/hooks/use-live-concert-state';
import useTranslate from 'src/hooks/use-translate';

type LiveConcertRerunListProps = {
  liveConcert: Pick<LiveConcert, 'endTime' | 'startTime' | 'streamStartTime' | 'reruns'>;
};

/**
 * Displays a numbered list of upcoming reruns for a LiveConcert based on the current timestamp
 * When no reruns are available, an empty object is returned
 *
 * @example
 * ```ts
 * <RerunList liveConcert={liveConcert} />
 * ```
 */
export default function LiveConcertRerunList({ liveConcert }: LiveConcertRerunListProps) {
  const t = useTranslate();
  const { upcomingReruns } = useLiveConcertState(liveConcert);

  if (upcomingReruns.length === 0) return null;

  // Render this component on the client only because the upcoming reruns might have already passed
  return (
    <RenderClientOnly>
      <ul data-test="live-concert-rerun-list" className="inline-flex flex-col gap-1">
        {upcomingReruns.map((rerun) => (
          <li key={rerun.startTime} className="dg-text-regular-4 flex items-center text-nowrap">
            <ReLiveIcon className="mr-1 size-5" />
            <span>{`${t('component__rerun_label')}`}</span>
            <Time value={rerun.startTime} style="shortTime" className="ml-2 text-surface-100" />
          </li>
        ))}
      </ul>
    </RenderClientOnly>
  );
}
